/**
 * Leaving this link, as it might be useful in the future, and was difficult to find:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 */

import useAlgolia from '~/composables/useAlgolia';
import {useGtm} from "@gtm-support/vue-gtm";
import {BillingAddress, LineItem, Order, OrderLineItem, SessionContext} from "@shopware-pwa/types";
import {GtmItem} from "~/types/GtmItem";
import {TrackingProduct} from "~/types/TrackingProduct";
import {useConsentStore} from "~/stores/consentStore";
import {sha256} from "ohash";

export function useTracking() {
    const {algoliaTrackAddToCart, algoliaTrackPurchase} = useAlgolia();
    const gtm = useGtm();
    const consentStore = useConsentStore();

    const trackingProductsToGtmItems = (products: TrackingProduct[]): GtmItem[] => {
        return products.map(product => {
            const gtmItem: GtmItem = {
                item_id: product.id,
                item_name: product.translated?.name ?? product.name ?? '',
                item_brand: product.manufacturer?.name ?? '',
                //item_category: product.categories?.map(category => category.translated?.name ?? category.name ?? category).join(', '), This is not stable enough. Algolia provides all possible categories and not a nested hierarchy.
                quantity: product.quantity ?? 0
            };

            if (product.activePrice) {
                gtmItem.price = product.activePrice;
            }

            return gtmItem;
        })
    }

    const lineItemsToGtmItems = (lineItems: LineItem[]): GtmItem[] => {
        return lineItems.map(lineItem => {
            const gtmItem: GtmItem = {
                item_id: lineItem.referencedId ?? '',
                item_name: lineItem.label ?? '',
                item_brand: '',
                quantity: lineItem.quantity ?? 0
            };

            if (lineItem.price) {
                gtmItem.price = Math.round(((lineItem.price?.unitPrice ?? 0) * 0.8) * 100) / 100;
            }

            return gtmItem;
        })
    }

    const orderLineItemsToGtmItems = (lineItems: OrderLineItem[]): GtmItem[] => {
        return lineItems.map(lineItem => {
            const gtmItem: GtmItem = {
                item_id: lineItem.productId ?? '',
                item_name: lineItem.label ?? '',
                item_brand: '',
                quantity: lineItem.quantity ?? 0
            };

            if (lineItem.unitPrice) {
                gtmItem.price = Math.round(((lineItem.unitPrice ?? 0) * 0.8) * 100) / 100;
            }

            return gtmItem;
        })

    };

    const getEventValue = (gtmItems: GtmItem[]): number => {
        let value = 0;
        gtmItems.forEach(item => {
            if (item.price) {
                value += item.price * item.quantity;
            }
        })
        return value;
    };

    const trackAddToCart = (sessionContext: SessionContext | undefined, products: TrackingProduct[], contextLabel?: string, queryId?: string) => {
        if (!consentStore.hasStatisticConsent) return;

        const gtmItems = trackingProductsToGtmItems(products);
        const eventValue = getEventValue(gtmItems);

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: sessionContext?.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems
            }
        })

        algoliaTrackAddToCart(sessionContext, products, contextLabel ?? 'Add to cart', queryId);
    };

    const trackRemoveFromCart = (sessionContext: SessionContext | undefined, lineItems: LineItem[]) => {
        if (!consentStore.hasStatisticConsent) return;

        const gtmItems = lineItemsToGtmItems(lineItems);
        const eventValue = getEventValue(gtmItems);

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'remove_from_cart',
            ecommerce: {
                currency: sessionContext?.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems
            }
        })
    }

    const trackAddToShoppinglist = (sessionContext: SessionContext | undefined, products: TrackingProduct[]) => {
        if (!consentStore.hasStatisticConsent) return;

        const gtmItems = trackingProductsToGtmItems(products);
        const eventValue = getEventValue(gtmItems);

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'add_to_wishlist', // Treating shopping list as a wishlist - still sort of a conversion.
            ecommerce: {
                currency: sessionContext?.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems
            }
        })
    }

    const trackCheckoutBegin = (sessionContext: SessionContext | undefined, lineItems: LineItem[]) => {
        if (!consentStore.hasStatisticConsent) return;

        const gtmItems = lineItemsToGtmItems(lineItems);
        const eventValue = getEventValue(gtmItems);

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'begin_checkout',
            ecommerce: {
                currency: sessionContext?.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems
            }
        })

    }

    const trackLogin = () => {
        if (!consentStore.hasStatisticConsent) return;

        gtm?.push({
            event: 'login',
        });
    }

    const trackSearch = (query: string) => {
        if (!consentStore.hasStatisticConsent) return;

        gtm?.push({
            event: 'search',
            search_term: query
        })

    }

    const trackSignUp = () => {
        if (!consentStore.hasStatisticConsent) return;

        gtm?.push({
            event: 'sign_up',
        });
    }

    const trackViewCart = (sessionContext: SessionContext | undefined, lineItems: LineItem[]) => {
        if (!consentStore.hasStatisticConsent) return;

        const gtmItems = lineItemsToGtmItems(lineItems);
        const eventValue = getEventValue(gtmItems);

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'view_cart',
            ecommerce: {
                currency: sessionContext?.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems
            }
        })

    }

    const trackViewItem = (sessionContext: SessionContext | undefined, products: TrackingProduct[]) => {
        if (!consentStore.hasStatisticConsent) return;

        const gtmItems = trackingProductsToGtmItems(products);
        const eventValue = getEventValue(gtmItems);

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'view_item',
            ecommerce: {
                currency: sessionContext?.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems
            }
        })
    }

    const trackPurchase = (sessionContext: SessionContext | undefined, order: Order) => {
        if (!consentStore.hasStatisticConsent) return;

        const items = order.lineItems ?? [];
        const gtmItems = orderLineItemsToGtmItems(items ?? []);
        const eventValue = getEventValue(gtmItems);

        let userData: any = {
            address: {}
        };

        const billingAddress: BillingAddress = order.billingAddress;

        if (order.orderCustomer?.email) {
            userData.sha256_email_address = sha256(order.orderCustomer.email);
        }

        if (billingAddress?.phoneNumber) {
            let phone = billingAddress.phoneNumber;
            if (!phone.startsWith('+')) {
                phone = `+45${phone.replaceAll(/^00/g, '')}`;
            }

            userData.sha256_phone_number = sha256(phone);
        }

        if (order.orderCustomer?.firstName) {
            userData.address.sha256_first_name = sha256(order.orderCustomer.firstName);
        }

        if (order.orderCustomer?.lastName) {
            userData.address.sha256_last_name = sha256(order.orderCustomer.lastName);
        }

        if (billingAddress?.street) {
            userData.address.street = billingAddress.street;
        }

        if (billingAddress?.city) {
            userData.address.city = billingAddress.city;
        }

        if (billingAddress?.zipcode) {
            userData.address.postal_code = billingAddress.zipcode;
        }

        if (billingAddress?.country) {
            userData.address.country = billingAddress.country?.iso ?? 'DK';
        }

        gtm?.push({ecommerce: null}); // Clear the ecommerce object, recommended by docs.
        gtm?.push({
            event: 'purchase',
            ecommerce: {
                currency: order.currency?.isoCode ?? 'DKK',
                value: eventValue,
                items: gtmItems,
                transaction_id: order.orderNumber,
                shipping: order.shippingTotal,
            },
            user_data: userData
        });


        gtm?.push({
            user_data: userData
        });


        algoliaTrackPurchase(order)

        let calculatedOrderListPrice = 0;
        items.forEach(item => {
            if (item.type == 'promotion' || item.type == 'giftcard') {
                calculatedOrderListPrice += item.totalPrice;
            } else if (item.payload?.customFields?.listepris) {
                calculatedOrderListPrice += item.payload.customFields.listepris * item.quantity;
            }
        })

        const orderNetValue = (order.price.netPrice * 0.95) - (order.shippingTotal / 1.25) - calculatedOrderListPrice;

        gtm?.push({
            event: 'conversion',
            ecommerce: {
                send_to: 'AW-998788046/qu6ACJG6-HAQzpeh3AM',
                ads_value: orderNetValue,
                ads_transaction_id: order.orderNumber,
            },
            user_data: userData
        })
    }

    return {
        trackAddToCart,
        trackRemoveFromCart,
        trackAddToShoppinglist,
        trackCheckoutBegin,
        trackLogin,
        trackSearch,
        trackSignUp,
        trackViewCart,
        trackViewItem,
        trackPurchase
    }
}
